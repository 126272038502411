import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/seo"
import { Row, Col, Accordion } from "react-bootstrap";
import { CaretRight } from "react-bootstrap-icons";
import { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const FaqsPageTemplate = ({
    faqTitle,
    faqHeading,
    faqDescription,
    // faqImage,
    // faqItems,
    generalFaqs,
    allgemeinefaq,
    faq1,
    faq2,
    faq3,
    // content,
    // contentComponent,
}) => {
    // const PageContent = contentComponent || Content;
    // const faqImg = getImage(faqImage) || faqImage;
    // const alt = `Gastrokalk Kunden Faqs`;

    return (
        <>
            <Row>
                <Col lg={8} className="pb-2">
                    <h1 className="page-title title-border">
                        {faqTitle}
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col className="col-lg-8 offset-lg-2">
                    {/* <FullWidthImage img={faqImg} alt={alt} height={120} />fwimg
                    <PreviewCompatibleImage imageInfo={faqImg} /> prvw */}
{/* 
                    <div
                        className="full-width-image-container"
                        style={{
                        backgroundImage: `url(${
                            faqImg.childImageSharp
                            ? faqImage.childImageSharp.fluid.src
                            : faqImage
                        })`,
                        }}
                    >
                        <p style={{backgroundColor: "#99d5ed", height: "5rem", width: "45rem"}}>Lorem ipsum</p>
                    </div> */}

                    { generalFaqs && 
                        (   
                            <ul className="faq-card">
                                {generalFaqs.map((genfaq, index) =>
                                    (
                                        <li key={index} className="card-header-faq"><a href={`#${index}`}>{genfaq.frage}</a></li>
                                    )
                                )}
                            </ul>
                        )
                    }
                    
                    <div className="content">
                        <h2 className="header-referenzen">
                            {faqHeading}
                        </h2>
                    </div>
                    {/* <p>description: {faqDescription}</p> */}

                    { generalFaqs && 
                        (
                            generalFaqs.map((genfaq, index) =>
                            (
                                <Accordion key={index} id={index} className="faq-card">
                                    <Accordion.Item eventKey={index}>
                                        <Accordion.Header className="card-header-faq"><b>Frage: </b><span style={{display: "inline-block", paddingLeft: "1rem"}}>{genfaq.frage}</span></Accordion.Header>
                                        <Accordion.Body>
                                            <div><CaretRight className="bi bi-caret-right" /><b>Antwort: </b>{genfaq.antwort}</div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            )
                            )
                        )
                    }

                    {/* { content && 
                        (
                            <PageContent className="faq-items" content={content} />
                        )
                    } */}

                    <div className="content">
                        <h2 className="header-referenzen">
                            {faqDescription}
                        </h2>
                    </div>


                    {allgemeinefaq &&
                        (
                            <Accordion className="faq-card">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className="card-header-faq">{allgemeinefaq.programme}</Accordion.Header>
                                    <Accordion.Body>
                                        {allgemeinefaq.infos.map((info) => (
                                            <div key={info}><CaretRight className="bi bi-caret-right" />{info}</div>
                                        ))}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        )
                    }

                    {faq1 &&
                        (
                            <Accordion className="faq-card">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header className="card-header-faq">{faq1.programme}</Accordion.Header>
                                    <Accordion.Body><CaretRight className="bi bi-caret-right" />{faq1.infos}</Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        )
                    }

                    {faq2 &&
                        (
                            <Accordion className="faq-card">
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header className="faq-card-header">{faq2.programme}</Accordion.Header>
                                    <Accordion.Body><CaretRight className="bi bi-caret-right" />{faq2.infos}</Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        )
                    }

                    {faq3 &&
                        (
                            <Accordion className="faq-card">
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header className="faq-card-header">{faq3.programme}</Accordion.Header>
                                    <Accordion.Body><CaretRight className="bi bi-caret-right" />{faq3.infos}</Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        )
                    }
                </Col>
            </Row>
        </>
    );
};

const FaqsPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;
    const  location = "faqs"

    return (
        <Layout location={location}>
            <FaqsPageTemplate
                faqTitle={frontmatter.faqTitle}
                faqHeading={frontmatter.faqHeading}
                faqDescription={frontmatter.faqDescription}
                faqImage={frontmatter.faqImage}
                faqItems={frontmatter.faqItems}
                generalFaqs={frontmatter.generalFaqs}
                allgemeinefaq={frontmatter.allgemeinefaq}
                faq1={frontmatter.faq1}
                faq2={frontmatter.faq2}
                faq3={frontmatter.faq3}
                faq4={frontmatter.faq4}
                faq5={frontmatter.faq5}
                faq6={frontmatter.faq6}
                faq7={frontmatter.faq7}
                faq8={frontmatter.faq8}
                faq9={frontmatter.faq9}
                faq10={frontmatter.faq10}
                faq11={frontmatter.faq11}
                contentComponent={HTMLContent}
                content={data.markdownRemark.html}
            />
        </Layout>
    );
};

FaqsPage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            content: PropTypes.string,
            frontmatter: PropTypes.object,
            faqItems: PropTypes.shape({
               heading: PropTypes.string,
               description: PropTypes.string,
            }),
            generalFaqs: PropTypes.shape({
                frage: PropTypes.string,
                antwort: PropTypes.string,
            }),
        }),
    }),
};

export default FaqsPage;

export const Head = () => <Seo title="Faqs" />

export const faqPageQuery = graphql`
  query FaqsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        faqTitle
        faqHeading
        faqDescription
        faqImage {
            childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        faqItems {
            heading
            description
        }
        generalFaqs {
            frage
            antwort
        }
        allgemeinefaq {
            programme
            infos 
        }
        faq1 {
            programme
            infos 
        }
        faq2 {
            programme
            infos 
        }
        faq3 {
            programme
            infos 
        }
        faq4 {
            programme
            infos 
        }
        faq5 {
            programme
            infos 
        }
        faq6 {
            programme
            infos 
        }
        faq7 {
            programme
            infos 
        }
        faq8 {
            programme
            infos 
        }
        faq9 {
            programme
            infos 
        }
        faq10 {
            programme
            infos 
        }
        faq11 {
            programme
            infos 
        }
      }
    }
  }
`;
